import { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { Alert, Button, CircularProgress, Grid, Typography } from '@mui/material'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import * as Yup from 'yup'

import SingleSelect from '../../components/form-elements/filter-select/single-select'
import { ClientSidePaginatedTable } from '../../components/table'
import { READ_PERMISSIONS_LIST, WRITE_PERMISSIONS_LIST } from '../../constants/permissions'
import { AlertContext, OwnUserContext, UserPermissionsContext } from '../../providers'
import Api from '../../utils/api'
import { handleFormSubmit } from '../../utils/form/form-helpers'

import useStyles from './create-edit.styles'

interface IProps {
  userId: number
}

interface IFormValues {
  templateId: number
}

const UsersTasks = (props: IProps) => {
  const { userId } = props

  const classes = useStyles()
  const history = useHistory()
  const { addAlert } = useContext(AlertContext)
  const userPermissions = useContext(UserPermissionsContext)
  const ownUser = useContext(OwnUserContext)

  const [taskTemplates, setTaskTemplates] = useState<ITaskTemplateType[]>([])
  const [taskTemplatesLoading, setTaskTemplatesLoading] = useState<boolean>(true)
  const [taskTemplatesError, setTaskTemplatesError] = useState<string>('')

  const [tasks, setTasks] = useState<ITaskType[]>([])
  const [error, setError] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [refreshCounter, setRefreshCounter] = useState<number>(0)

  const hasPermissionToCreateTask =
    (userPermissions.includes(READ_PERMISSIONS_LIST.taskTemplates) &&
      userPermissions.includes(READ_PERMISSIONS_LIST.tasks) &&
      userPermissions.includes(WRITE_PERMISSIONS_LIST.tasks)) ||
    ownUser?.isRoot

  useEffect(() => {
    let isSubscribed = true
    if (hasPermissionToCreateTask) {
      setTaskTemplatesLoading(true)

      Api.get('/api/v2/task-templates').then(res => {
        if (isSubscribed) {
          if (!res.error) {
            setTaskTemplatesLoading(false)
            setTaskTemplates(res.templates)
          } else {
            setTaskTemplatesError(res.message)
            addAlert({ alertType: 'error', message: res.message })
          }
        }
      })
    }

    return () => {
      isSubscribed = false
    }
  }, [addAlert, hasPermissionToCreateTask])

  useEffect(() => {
    setIsLoading(true)
    Api.get(`/api/v2/tasks?ownerId=${userId}&ownerType=user`).then(res => {
      setIsLoading(false)
      if (res.error) {
        setError(res?.message ?? 'Something went wrong, we could not load tasks assigned to this user')
      } else {
        setError('')
        setTasks(res.tasks ?? [])
      }
    })
  }, [userId, refreshCounter])

  const columns = [
    { name: 'Title', options: { filter: false, sort: false } },
    { name: 'Description', options: { filter: false, sort: false } },
    { name: 'Status', options: { filter: false, sort: false } },
  ]

  const tableData = useMemo(() => {
    const allRowData: Array<Array<number | string | React.ReactNode>> = tasks.map(item => {
      return [item.title, item.description, item.currentStatus]
    })

    return allRowData
  }, [tasks])

  const createTask = async (values: IFormValues, actions: FormikHelpers<IFormValues>) => {
    const taskPayload = {
      taskTemplateId: values.templateId,
      userId,
    }

    try {
      handleFormSubmit(await Api.post('/api/v2/tasks', taskPayload), addAlert, history, null, values, actions)
      setRefreshCounter(refreshCounter + 1)
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not create the task' })
    }
  }

  const schema = Yup.object().shape({
    templateId: Yup.number().required('Required'),
  })

  return (
    <>
      <Grid container={true}>
        <Grid item={true} xs={12} sm={12} md={12} lg={6} xl={6} sx={{ padding: 3 }}>
          <Typography variant="h6">Create Task</Typography>
          {!taskTemplatesLoading && taskTemplatesError && (
            <Grid item={true} xs={12} container={true} justifyContent="center">
              <Alert severity="error">{taskTemplatesError}</Alert>
            </Grid>
          )}
          {!hasPermissionToCreateTask && (
            <Grid item={true} xs={12} container={true} justifyContent="center">
              <Alert severity="error">No permission to create tasks</Alert>
            </Grid>
          )}
          {!taskTemplatesError && hasPermissionToCreateTask && (
            <Formik data-testid="task-create-form" initialValues={{ templateId: 0 }} enableReinitialize={true} validationSchema={schema} onSubmit={createTask}>
              {({ isValid, isSubmitting, dirty }: FormikProps<IFormValues>) => {
                return (
                  <Form>
                    <SingleSelect<ITaskTemplateType, IFormValues>
                      name="templateId"
                      label="Please select a task template"
                      required={true}
                      items={taskTemplates}
                      loading={taskTemplatesLoading}
                      displayField="title"
                      initialValue={undefined}
                    />
                    <Grid container={true} justifyContent="flex-end">
                      <Button type="submit" disabled={!isValid || isSubmitting || !dirty}>
                        Create
                      </Button>
                    </Grid>
                  </Form>
                )
              }}
            </Formik>
          )}
        </Grid>

        <Grid item={true} xs={12} sm={12} md={12} lg={6} xl={6}>
          <Grid container={true} spacing={3}>
            {isLoading && !error && (
              <Grid item={true} xs={12} container={true} justifyContent="center" className={classes.bottomSpacing}>
                <CircularProgress />
              </Grid>
            )}

            {!isLoading && error && (
              <Grid item={true} xs={12} container={true} justifyContent="center">
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}

            {!isLoading && !error && tasks.length === 0 && (
              <Grid item={true} xs={12} container={true} justifyContent="center">
                <Alert severity="info">There are currently no tasks assigned to this user</Alert>
              </Grid>
            )}

            {!isLoading && !error && tasks.length > 0 && (
              <Grid item={true} xs={12} sx={{ padding: 3 }}>
                <ClientSidePaginatedTable
                  title="Assigned Tasks"
                  entityDeleteEndpoint="/api/v2/tasks"
                  idList={tasks.map(({ id }) => ({ id }))}
                  refresh={() => setRefreshCounter(refreshCounter + 1)}
                  columns={columns}
                  loading={false}
                  error={null}
                  data={tableData}
                  options={{
                    filter: false,
                    search: false,
                    searchOpen: false,
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default UsersTasks
