import { useContext, useEffect, useState } from 'react'
import { Controlled as ControlledCodeMirror, UnControlled as CodeMirror } from 'react-codemirror2'
import { useHistory, useParams } from 'react-router-dom'
import { Button, CardActions, CardContent, Grid, Typography } from '@mui/material'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import Alert from '../../components/alert'
import FormikCheckbox from '../../components/form-elements/checkbox'
import SingleFilterSelect from '../../components/form-elements/filter-select/single-select'
import FormikTextField from '../../components/form-elements/text-field'
import ConfirmDeletionModal from '../../components/modal'
import OfficialDocsLink from '../../components/official-doc-link'
import PermissionsFormError, { combineErrorMessages } from '../../components/permissions-form-error/form-error-message'
import { SUPPORTED_LANGUAGES_MAP } from '../../constants'
import { ThemeContext } from '../../providers/theme'
import Api from '../../utils/api'
import { handleDelete, handleFormSubmit, redirectToListViewOnError } from '../../utils/form/form-helpers'
import { base64ToString, stringToBase64 } from '../../utils/helper-functions'
import { useDocumentTitle, useToggle } from '../../utils/hooks'

import { AlertContext } from './../../providers'
import useReactionStyles from './create-edit.styles'

import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/gruvbox-dark.css'

type IReactionTemplateFormikField = Record<
  string,
  {
    subject: string
    body: string
    existing: boolean
  }
>

interface IReactionPayload {
  name: string
  active: boolean
  eventType: string
  ruleId: number | string
  type: string
  subjectTemplate?: string
  fromName?: string
  fromEmail?: string
  urlTemplate?: string
  headerTemplate?: string
  delayParams: IReactionDelayParamsType
}

interface IFormValues {
  name: string
  active: boolean
  ruleId: string | number
  eventType: string
  reactionType: string
  templateLanguage: string
  bodyTemplates: IReactionTemplateFormikField
  defaultFromName: boolean
  newFromName: string
  defaultFromEmail: boolean
  newFromEmail: string
  urlTemplate: string
  headerTemplate: string
  delayParams: IReactionDelayParamsType
}

const defaultTemplates = Object.fromEntries(
  // for each language, map it to the default empty subject/body
  Object.keys(SUPPORTED_LANGUAGES_MAP).map(lang => [lang, { subject: '', body: '', existing: false }])
)

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }

  return true
}

export const validateCodemirrors = (values: any) => {
  const errors: any = {}

  if (values.reactionType === 'webhook') {
    if (values.headerTemplate !== '' && !isJsonString(values.headerTemplate)) {
      errors.headerTemplate = 'Must be a valid json with double quotes'
    }
  }

  return errors
}

export const ReactionsForm = () => {
  const classes = useReactionStyles()

  const history = useHistory()
  const { addAlert } = useContext(AlertContext)
  const params = useParams<{ reactionId: string }>()
  const reactionId = params.reactionId
  const isEditMode = reactionId !== 'new'

  const { themeKey } = useContext(ThemeContext)

  const eventTypes: string[] = ['enter', 'exit']
  const reactionTypes: string[] = ['email', 'sms', 'webhook', 'phone', 'push']

  const [currentReaction, setCurrentReaction] = useState<IReactionType | null>(null)
  const [currentReactionTemplates, setCurrentReactionTemplates] = useState<IReactionTemplateFormikField | null>(null)
  const [rules, setRules] = useState<IRuleType[]>([])
  const [rulesError, setRulesError] = useState<string>('')
  const [rulesLoading, setRulesLoading] = useState<boolean>(false)

  const [showConfirmDeletionModal, toggleConfirmModal] = useToggle(false)

  useDocumentTitle('Reactions', isEditMode ? currentReaction?.name : 'new')

  useEffect(() => {
    let isSubscribed = true
    setRulesLoading(true)

    Api.get('/api/rules').then(rulesData => {
      setRulesLoading(false)
      if (isSubscribed && !rulesData.error) {
        setRules(rulesData)
      } else {
        setRulesError(rulesData.message)
        addAlert({ alertType: 'error', message: rulesData.message })
      }
    })

    return () => {
      isSubscribed = false
    }
  }, [addAlert])

  useEffect(() => {
    let isSubscribed = true
    if (isEditMode) {
      Promise.all([Api.get(`/api/v2/reactions/${reactionId}`), Api.get(`/api/v2/reactions/${reactionId}/templates`)]).then(
        ([retrievedReaction, retrievedReactionTemplates]) => {
          redirectToListViewOnError(retrievedReaction, history, addAlert, '/reactions')

          if (isSubscribed && !retrievedReaction.error) {
            setCurrentReaction(retrievedReaction)
            // populate the default/empty templates for every language
            // and then fill in the languages that actually exist from the /templates api
            // backend wants the body to be decoded from base64
            // mark the template as "existing" so we know if we need to POST or PUT later when saving
            const templates = !retrievedReactionTemplates.error
              ? Object.fromEntries(
                  retrievedReactionTemplates?.templates?.map((template: IReactionTemplateType) => [
                    template.language,
                    { subject: template.subject, body: base64ToString(template.body), existing: true },
                  ])
                )
              : {}
            setCurrentReactionTemplates({
              ...defaultTemplates,
              ...templates,
            })
          }
        }
      )
    }
    return () => {
      isSubscribed = false
    }
  }, [isEditMode, reactionId, history, addAlert])

  const saveReaction = async (values: IFormValues, actions: FormikHelpers<IFormValues>) => {
    const payload: IReactionPayload = {
      name: values.name,
      active: values.active,
      eventType: values.eventType,
      ruleId: values.ruleId,
      type: values.reactionType,
      delayParams: values.delayParams,
    }

    if (values.reactionType !== 'webhook' && !values.defaultFromName) {
      payload.fromName = values.newFromName
    }

    if (values.reactionType === 'email') {
      if (!values.defaultFromEmail) {
        payload.fromEmail = values.newFromEmail
      } else {
        // we must send an empty string here, otherwise the value won't get reset
        payload.fromEmail = ''
      }

      if (!values.defaultFromName) {
        payload.fromName = values.newFromName
      } else {
        // we must send an empty string here, otherwise the value won't get reset
        payload.fromName = ''
      }
    }

    if (values.reactionType === 'webhook') {
      payload.urlTemplate = values.urlTemplate
      if (values.headerTemplate !== '') {
        payload.headerTemplate = values.headerTemplate
      }
    }

    try {
      let savedReactionId: number
      if (!isEditMode) {
        handleFormSubmit(
          (savedReactionId = (await Api.post(`/api/v2/reactions`, payload)).id),
          addAlert,
          history,
          null, // empty route because we need to redirect later
          values,
          actions
        )
      } else {
        handleFormSubmit(await Api.put(`/api/v2/reactions/${reactionId}`, payload), addAlert, history, null, values, actions)
        savedReactionId = Number(reactionId)
      }

      // update the templates for all the languages
      if (savedReactionId > 0) {
        await Promise.all(
          Object.keys(SUPPORTED_LANGUAGES_MAP)
            .map(lang => {
              // if the subject and body are both empty, don't try to save it
              if (values.bodyTemplates[lang].subject === '' && values.bodyTemplates[lang].body === '') {
                return undefined
              }

              const saveMethod = values.bodyTemplates[lang].existing ? Api.put : Api.post

              return saveMethod(`/api/v2/reactions/${savedReactionId}/templates/${lang}`, {
                subject: values.bodyTemplates[lang].subject,
                body: stringToBase64(values.bodyTemplates[lang].body),
              })
            })
            .filter(x => x)
        ).then(responses => {
          // update the "existing" tag on the formik form so the next edit will use PUT instead of POST
          responses.forEach(response => {
            actions.setFieldValue(`bodyTemplates.${response.language}.existing`, true)
          })
        })
        // redirect after saving
        if (!isEditMode) {
          history.push(`/reactions/${savedReactionId}`)
        }
      }
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not submit the reaction' })
    }
  }

  const deleteReaction = async () => {
    try {
      handleDelete(await Api.del(`/api/v2/reactions`, reactionId), addAlert, history, '/reactions')
    } catch (e) {
      const message = (e as Error).message
      addAlert({ alertType: 'error', message: String(message) || 'Could not delete the reaction' })
    }
  }

  const schema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    ruleId: Yup.number()
      .required()
      .positive('Please select a rule'), // -1 is used to indicate that no value is selected in signle-select, so we need postiive() validation here
    eventType: Yup.string().required('Please select event type'),
    reactionType: Yup.string().required('Please select reaction type'),
    urlTemplate: Yup.string().when('reactionType', {
      is: 'webhook',
      then: Yup.string()
        .required('URL template is required')
        .matches(/^https?:\/\/.*?/, 'URL must start with http(s)://'),
    }),
    newFromEmail: Yup.string().when('defaultFromEmail', {
      is: false,
      then: Yup.string().email('Must be a valid email'),
    }),
    bodyTemplates: Yup.object().required('Body Template is required'),
    delayParams: Yup.object().shape({
      delay: Yup.number().required(),
      landline: Yup.number().required(),
      primaryOnly: Yup.boolean(),
      type: Yup.string().oneOf(['ALWAYS', 'NOACK']),
    }),
  })

  const initialValues: IFormValues =
    isEditMode && currentReaction && currentReactionTemplates
      ? {
          name: currentReaction.name,
          active: currentReaction.active,
          ruleId: currentReaction.ruleId,
          eventType: currentReaction.eventType,
          reactionType: currentReaction.type,
          templateLanguage: 'en-US',
          bodyTemplates: currentReactionTemplates,
          defaultFromName: currentReaction.fromName === '' ? true : false,
          newFromName: currentReaction.fromName,
          defaultFromEmail: currentReaction.fromEmail === '' ? true : false,
          newFromEmail: currentReaction.fromEmail,
          urlTemplate: currentReaction.urlTemplate,
          headerTemplate: currentReaction.headerTemplate,
          delayParams: currentReaction.delayParams,
        }
      : {
          name: '',
          active: false,
          ruleId: '',
          eventType: 'enter',
          reactionType: 'email',
          templateLanguage: 'en-US',
          bodyTemplates: defaultTemplates,
          defaultFromName: true,
          newFromName: '',
          defaultFromEmail: true,
          newFromEmail: '',
          urlTemplate: '',
          headerTemplate: '',
          delayParams: {
            delay: 0,
            landline: 0,
            primaryOnly: false,
            type: 'ALWAYS',
          },
        }

  return (
    <>
      {!rulesError && (
        <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={saveReaction} validationSchema={schema} validate={validateCodemirrors}>
          {({ values, isValid, errors, isSubmitting, setFieldValue, dirty }) => (
            <Form>
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="name">
                    <FormikTextField initialized={isEditMode} data-testid="name" name="name" label="Name" required={true} />
                  </Grid>

                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Alert
                      alertType="warning"
                      message="When a node triggers a Rule, the corresponding Reaction sends emails or SMS messages to subscribed users."
                    />
                  </Grid>

                  <Grid item={true} xs={12} data-cy="isActive">
                    <FormikCheckbox name="active" label="Active" />
                  </Grid>

                  <Grid item={true} xs={12}>
                    <Typography className={classes.denseFormSubheader} variant="subtitle1">
                      Rule
                    </Typography>
                  </Grid>

                  <Grid item={true} xs={12} sm={12} md={6} lg={4} xl={4} data-cy="rulesSelection">
                    <SingleFilterSelect
                      name="ruleId"
                      label="Select the Rule that creates this reaction:"
                      required={true}
                      displayField="name"
                      shortcutCallback={(id: number) => history.push(`/rules/${id}`)}
                      items={rules}
                      loading={rulesLoading}
                      initialValue={currentReaction?.ruleId}
                    />
                  </Grid>

                  <Grid item={true} xs={8} />

                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="eventTypeSelection">
                    <FormikTextField initialized={isEditMode} select={true} label="Event Type" name="eventType" required={true}>
                      {eventTypes.map((eventType: string) => {
                        return (
                          <option key={eventType} value={eventType}>
                            {eventType}
                          </option>
                        )
                      })}
                    </FormikTextField>
                  </Grid>

                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Alert
                      alertType="warning"
                      message="Enter: when a node first triggers the Rule. Exit: when a node has already triggered the rule and then exits the alarm state."
                    />
                  </Grid>

                  <Grid container={true} item={true} xs={12} columnSpacing={4}>
                    <Grid item={true} xs={12}>
                      <Typography className={classes.denseFormSubheader} variant="subtitle1">
                        Delay Params
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                      <FormikTextField initialized={isEditMode} name="delayParams.delay" label="Delay" type="number" required={true} />
                    </Grid>
                    <Grid container={true} item={true} xs={12} md={6} alignItems="flex-end">
                      <FormikCheckbox name="delayParams.primaryOnly" label="Primary Only" />
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                      <FormikTextField initialized={isEditMode} name="delayParams.landline" label="Landline" type="number" required={true} />
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                      <FormikTextField initialized={isEditMode} name="delayParams.type" label="Type" required={true} select={true}>
                        <option value="ALWAYS">ALWAYS</option>
                        <option value="NOACK">NOACK</option>
                      </FormikTextField>
                    </Grid>
                  </Grid>

                  <Grid item={true} xs={12}>
                    <Typography className={classes.denseFormSubheader} variant="subtitle1">
                      Response
                    </Typography>
                  </Grid>

                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="reactionTypeSelection">
                    <FormikTextField initialized={isEditMode} select={true} label="Reaction Type" name="reactionType" required={true}>
                      {reactionTypes.map((reactionType: string) => {
                        return (
                          <option key={reactionType} value={reactionType}>
                            {reactionType}
                          </option>
                        )
                      })}
                    </FormikTextField>
                  </Grid>

                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Alert
                      alertType="warning"
                      message="You have full control over the email/SMS message sent to subscribed users and Webhook messages sent to target URLs. The body template is HTML for email/SMS and application/json for webhooks, and you have access to several variables inside of it."
                    />
                  </Grid>

                  <Grid item={true} xs={1} sm={1} md={6} lg={6} xl={6} />

                  <Grid item={true} xs={11} sm={11} md={6} lg={6} xl={6} data-cy="docs">
                    <OfficialDocsLink url="https://github.com/meshifyiot/public-carbon-docs/blob/master/concepts/reactions.md" />
                  </Grid>

                  <Grid item={true} xs={12}>
                    <FormikTextField select={true} name="templateLanguage" label="Language" variant="outlined" margin="none" required={true}>
                      {Object.entries(SUPPORTED_LANGUAGES_MAP).map(([languageCode, properName]) => (
                        <option key={languageCode} value={languageCode}>
                          {properName}
                        </option>
                      ))}
                    </FormikTextField>
                  </Grid>

                  {values.reactionType === 'email' && (
                    <Grid item={true} xs={12}>
                      <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="subjectTemplate">
                        <FormikTextField
                          initialized={isEditMode}
                          name={`bodyTemplates.${values.templateLanguage}.subject`}
                          label="Subject Template"
                          required={true}
                        />
                      </Grid>
                    </Grid>
                  )}

                  {values.reactionType === 'webhook' && (
                    <>
                      <Grid item={true} xs={12}>
                        <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                          <FormikTextField
                            initialized={isEditMode}
                            name="urlTemplate"
                            label="URL Template"
                            required={true}
                            helperText="Should start with https"
                          />
                        </Grid>
                      </Grid>

                      <Grid item={true} xs={12}>
                        <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                          <div className={classes.codemirrorLabel}>Header Template</div>
                          <CodeMirror
                            value={currentReaction?.headerTemplate ?? ''}
                            className={classes.headerTemplate}
                            options={{
                              mode: 'xml',
                              lineWrapping: true,
                              lineNumbers: true,
                              theme: themeKey === 'dark' ? 'gruvbox-dark' : 'default',
                            }}
                            onChange={(editor, data, value) => {
                              setFieldValue('headerTemplate', value)
                            }}
                          />
                          {errors.headerTemplate && <div className={classes.codemirrorError}>{errors.headerTemplate}</div>}
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6} data-cy="codeMirror">
                    <div className={classes.codemirrorLabel}>
                      Body Template<sup>*</sup>
                    </div>
                    <ControlledCodeMirror
                      value={values.bodyTemplates[values.templateLanguage].body}
                      className={classes.border}
                      options={{
                        mode: 'xml',
                        lineWrapping: true,
                        lineNumbers: true,
                        theme: themeKey === 'dark' ? 'gruvbox-dark' : 'default',
                      }}
                      onBeforeChange={(editor, data, value) => {
                        if (data.origin) {
                          setFieldValue(`bodyTemplates.${values.templateLanguage}.body`, value)
                        }
                      }}
                    />

                    {errors.bodyTemplates && (
                      <div className={classes.codemirrorError} data-testid="bodyTemplateError">
                        Body Template is required
                      </div>
                    )}
                  </Grid>

                  {values.reactionType !== 'webhook' && (
                    <Grid item={true} xs={6}>
                      <Grid container={true} spacing={3}>
                        {['sms', 'phone', 'email', ''].find(item => item === values.reactionType) != null && (
                          <Grid item={true} xs={12} data-cy="fromName">
                            <div className={classes.checkboxLabel}>From Name</div>
                            {!values.defaultFromName && <FormikTextField initialized={isEditMode} name="newFromName" label="From Name" required={false} />}
                            <FormikCheckbox name="defaultFromName" label="Default" />
                          </Grid>
                        )}

                        {values.reactionType === 'email' && (
                          <Grid item={true} xs={12} data-cy="fromEmail">
                            <div className={classes.checkboxLabel}>From Email</div>
                            {!values.defaultFromEmail && <FormikTextField name="newFromEmail" label="From Email" required={false} />}
                            <FormikCheckbox name="defaultFromEmail" label="Default" />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </CardContent>

              <CardActions>
                <Button color="primary" type="submit" disabled={!isValid || isSubmitting || (!isEditMode && !dirty)} data-cy="saveBtn" data-testid="saveBtn">
                  Save
                </Button>

                <Button type="button" onClick={() => history.push('/reactions')} color="inherit" data-cy="cancelBtn" data-testid="cancelBtn">
                  Cancel
                </Button>

                {isEditMode && (
                  <Button type="button" data-cy="deleteBtn" data-testid="deleteBtn" className={classes.deleteBtn} onClick={toggleConfirmModal}>
                    Delete
                  </Button>
                )}
              </CardActions>
            </Form>
          )}
        </Formik>
      )}

      <ConfirmDeletionModal
        show={isEditMode && showConfirmDeletionModal}
        confirmModalAction={deleteReaction}
        closeModal={toggleConfirmModal}
        dialogTitle="Are you sure you want to delete this reaction?"
      />

      {rulesError && (
        <div className={classes.permissionsAlertWrapper}>
          <PermissionsFormError errorMessages={combineErrorMessages(rulesError)} formName="Reaction Form" listViewRoute="/reactions" hasGoBackButton={true} />
        </div>
      )}
    </>
  )
}

export default ReactionsForm
